class ImageService {
    static getImageFilePaths(gallery) {
        try {
            let imageFilePaths = [];
            for (let i in gallery.fileNames)
                imageFilePaths.push(
                    `${process.env.REACT_APP_MAIN_DIR}/images/${gallery.folder}/${gallery.fileNames[i]}`
                );
            return imageFilePaths;
        } catch {
            return [];
        }
    }

    static setTimestamp() {
        const date = new Date();
        return `${date.getFullYear()}-${
            date.getMonth() + 1
        }-${date.getDate()}_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;
    }
}

export default ImageService;
