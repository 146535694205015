import {
    FullPageSection,
    Page,
} from '../../common/page_components/PageComponents';
import { ExplanationSection, GallerySection } from './ArtComponent';

function Art() {
    return (
        <Page>
            <FullPageSection noContainer alignTop>
                <ExplanationSection />
                <GallerySection />
            </FullPageSection>
        </Page>
    );
}

export default Art;
