import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
    useWindowSize,
    useWindowSizeLimits,
} from '../../../providers/WindowProvider';
import { changeTheme } from '../../../providers/ColorThemeProvider';

import './NavBar.css';

function NavBarLogo(props) {
    return (
        <button onClick={props.onClick} className="empty logo" id="navbar_logo">
            {props.heading}
        </button>
    );
}

function NavBarButton(props) {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <button
            disabled={location.pathname === props.route}
            onClick={() => {
                navigate(props.route);
            }}
            className="hollow navbar_button"
        >
            {props.heading}
        </button>
    );
}

function NavBarSeperator() {
    return <div className="navbar_seperator"></div>;
}

function NavBarMenu(props) {
    let navBarMenuClass;
    if (props.toggle) {
        if (props.open) {
            navBarMenuClass = 'navbar_menu toggle';
        } else {
            navBarMenuClass = 'navbar_menu toggle hidden';
        }
    } else {
        navBarMenuClass = 'navbar_menu';
    }
    return (
        <div className={navBarMenuClass}>
            <div className="navbar_menu_item">
                <NavBarButton route={'/'} heading="HOME" />
            </div>
            <div className="navbar_menu_item">
                <NavBarButton route={'/coding'} heading="CODING" />
            </div>
            <div className="navbar_menu_item">
                <NavBarButton route={'/art'} heading="ART" />
            </div>
            <div className="navbar_menu_item">
                <NavBarButton route={'/blog'} heading="BLOG" />
            </div>
        </div>
    );
}

function NavBarMenuToggleButton(props) {
    return (
        <button onClick={props.onClick} className="hollow navbar_button">
            {props.heading}
        </button>
    );
}

function NavBar() {
    const windowSizeLimits = useWindowSizeLimits();
    const windowSize = useWindowSize();

    const [navOpen, setNavOpen] = useState(false);

    const location = useLocation();

    // Automatically close the toggle menu
    useEffect(() => {
        setNavOpen(false);
    }, [location]);

    return (
        <>
            <nav className="navbar">
                <div className="navbar_container">
                    <div className="navbar_container_item">
                        <NavBarLogo onClick={changeTheme} heading="AEN" />
                    </div>
                    <div className="navbar_container_item">
                        <NavBarSeperator />
                    </div>
                    <div className="navbar_container_item">
                        {windowSize.width < windowSizeLimits.small ? (
                            <NavBarMenuToggleButton
                                onClick={() => setNavOpen(!navOpen)}
                                heading={navOpen ? 'CLOSE' : 'MENU'}
                            />
                        ) : (
                            <NavBarMenu />
                        )}
                    </div>
                </div>
                {windowSize.width < windowSizeLimits.small && (
                    <NavBarMenu toggle={true} open={navOpen} />
                )}
            </nav>
        </>
    );
}

export default NavBar;
