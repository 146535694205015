import { useEffect, useState } from 'react';
import { FaRegHandPointDown } from 'react-icons/fa';

import { homeContent } from '../../../assets/content/Contents';
import {
    FitPageSection,
    FullPageSection,
    SectionItem,
    Spacing,
} from '../../common/page_components/PageComponents';
import { goToSection, useBarType } from '../../../providers/WindowProvider';

import './Home.css';

function WelcomeSection() {
    const [randomIndex, setRandomIndex] = useState(0);

    function GoDownIcon() {
        const barType = useBarType();
        return (
            <div
                id="go_down_icon"
                onClick={() => {
                    goToSection('about', barType === 'navbar' ? 80 : 0);
                }}
            >
                <FaRegHandPointDown size="30" />
            </div>
        );
    }

    // Set random welcome message at start
    useEffect(() => {
        let welcomeMessageAmount = homeContent.primaryWelcomeMessages.length;
        setRandomIndex(Math.floor(Math.random() * welcomeMessageAmount));
    }, []);

    return (
        <FullPageSection>
            <SectionItem>
                <h1 id="primary_welcome_text">
                    {homeContent.primaryWelcomeMessages[randomIndex]}
                </h1>
            </SectionItem>
            <SectionItem>
                <p id="secondary_welcome_text" className="large">
                    {homeContent.secondaryWelcomeMessage}
                </p>
            </SectionItem>
            <Spacing height="10px" />
            <SectionItem>
                <GoDownIcon />
            </SectionItem>
        </FullPageSection>
    );
}

function AboutSection() {
    return (
        <FitPageSection id="about">
            <SectionItem>
                <h2>{homeContent.aboutTitle}</h2>
            </SectionItem>
            {homeContent.aboutText.map((text, key) => (
                <SectionItem key={key} align="left">
                    <p className="align_left">{text}</p>
                </SectionItem>
            ))}
        </FitPageSection>
    );
}

export { WelcomeSection, AboutSection };
