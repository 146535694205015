import {
    Page,
    FullPageSection,
    Seperator,
} from '../../common/page_components/PageComponents';
import { BlogPostsSection, MediumSection } from './BlogComponents';

function Blog() {
    return (
        <Page>
            <FullPageSection noContainer alignTop>
                <BlogPostsSection />
            </FullPageSection>
            <Seperator />
            <MediumSection />
        </Page>
    );
}

export default Blog;
