class BlogService {
    static async fetchMediumContent(username, from = null, to = null) {
        try {
            const response = await fetch(
                `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/${username}`
            );
            if (response.status !== 200) {
                throw Error;
            }
            const data = await response.json();
            if (from === null || to === null) {
                return data.items;
            } else {
                return data.items.slice(from, to);
            }
        } catch {
            return [];
        }
    }

    static arrangeContent(content) {
        function parseDateTime(dateTime) {
            const [date, time] = dateTime.split(' ');
            const [year, month, day] = date.split('-');
            const [hour, minute] = time.split(':');
            return `${day}/${month}/${year} at ${hour}:${minute}`;
        }

        let posts = [];
        for (let i in content) {
            let post = {
                title: content[i].title,
                date: parseDateTime(content[i].pubDate),
                url: content[i].link,
            };
            posts.push(post);
        }
        return posts;
    }
}

export default BlogService;
