import { useEffect, useState } from 'react';

import BlogService from '../../../services/BlogService';
import {
    FitPageSection,
    SectionItem,
    Spacing,
} from '../../common/page_components/PageComponents';
import { blogContent, commonContent } from '../../../assets/content/Contents';
import PlaceholderMessage from '../../common/placeholder/PlaceholderMessage';

import './Blog.css';

function BlogPostsSection() {
    const [blogPostsLoading, setBlogPostsLoading] = useState(true);
    const [blogPosts, setBlogPosts] = useState([]);

    // Get blog posts
    useEffect(() => {
        async function fetchBlogPosts() {
            setBlogPostsLoading(true);
            let fetchedBlogPosts = await BlogService.fetchMediumContent(
                '@aliemrenebiler',
                0,
                10
            );
            setBlogPosts(BlogService.arrangeContent(fetchedBlogPosts));
            setBlogPostsLoading(false);
        }

        fetchBlogPosts();
    }, []);

    function PostButton(props) {
        return (
            <button
                onClick={() => {
                    props.url && window.open(props.url, '_blank');
                }}
                className="outlined post_button"
            >
                <div className="post_button_container">
                    <h3 className="post_button_title">{props.title}</h3>
                    <p className="small post_button_text">{props.text}</p>
                </div>
            </button>
        );
    }

    function renderBlogContent() {
        if (blogPostsLoading) {
            return (
                <div className="expand_and_center">
                    <PlaceholderMessage message={commonContent.loading} />
                </div>
            );
        } else if (blogPosts.length < 1) {
            return (
                <div className="expand_and_center">
                    <PlaceholderMessage message={blogContent.noPosts} />
                </div>
            );
        } else {
            return (
                <FitPageSection>
                    {blogPosts.map((post, key) => {
                        return (
                            <SectionItem expand key={key}>
                                <PostButton
                                    title={post.title}
                                    text={post.date}
                                    url={post.url}
                                />
                            </SectionItem>
                        );
                    })}
                </FitPageSection>
            );
        }
    }

    return renderBlogContent();
}

function MediumSection() {
    function MediumButton() {
        return (
            <button
                onClick={() => {
                    window.open('https://medium.com/@aliemrenebiler', '_blank');
                }}
                className="outlined"
                id="medium_button"
            >
                @aliemrenebiler on Medium
            </button>
        );
    }

    return (
        <FitPageSection>
            <SectionItem>
                <h2 id="medium_title">{blogContent.mediumTitle}</h2>
            </SectionItem>
            <SectionItem>
                <p>{blogContent.mediumText}</p>
            </SectionItem>
            <Spacing height="10px" />
            <SectionItem>
                <MediumButton />
            </SectionItem>
        </FitPageSection>
    );
}

export { BlogPostsSection, MediumSection };
