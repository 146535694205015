import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const getWindowSize = () => {
    return {
        width: window.innerWidth,
        height: window.innerHeight,
    };
};

const useWindowSizeLimits = () => {
    return {
        xsmall: getComputedStyle(document.documentElement).getPropertyValue(
            '--window-size-xsmall'
        ),
        small: getComputedStyle(document.documentElement).getPropertyValue(
            '--window-size-small'
        ),
        medium: getComputedStyle(document.documentElement).getPropertyValue(
            '--window-size-medium'
        ),
        large: getComputedStyle(document.documentElement).getPropertyValue(
            '--window-size-large'
        ),
        xlarge: getComputedStyle(document.documentElement).getPropertyValue(
            '--window-size-xlarge'
        ),
    };
};

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowSize;
};

const useBarType = () => {
    const location = useLocation();
    const windowSize = useWindowSize();
    const windowSizeLimits = useWindowSizeLimits();
    let barType;

    // Set if sidebar is active or not
    if (
        location.pathname === '/' &&
        windowSize.width > windowSizeLimits.large &&
        windowSize.height > windowSizeLimits.xsmall
    ) {
        barType = 'sidebar';
    } else {
        barType = 'navbar';
    }

    return barType;
};

const goToSection = (id, offset = 0) => {
    const element = document.getElementById(id);
    if (element) {
        window.scrollTo({
            behavior: 'smooth',
            top:
                element.getBoundingClientRect().top -
                document.body.getBoundingClientRect().top -
                offset,
        });
    }
};

const setScrollLock = (locked) => {
    if (locked === true) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
};

export {
    useWindowSizeLimits,
    useWindowSize,
    useBarType,
    goToSection,
    setScrollLock,
};
