import { useNavigate, useLocation } from 'react-router-dom';

import { changeTheme } from '../../../providers/ColorThemeProvider';

import './SideBar.css';

function SideBarLogo(props) {
    return (
        <button
            onClick={props.onClick}
            className="empty logo"
            id="sidebar_logo"
        >
            {props.heading}
        </button>
    );
}

function SideBarButton(props) {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <button
            disabled={location.pathname === props.route}
            onClick={() => {
                navigate(props.route);
            }}
            className="hollow sidebar_button"
        >
            {props.heading}
        </button>
    );
}

function SideBarMenu() {
    return (
        <div className="sidebar_menu">
            <div className="sidebar_menu_item">
                <SideBarButton route={'/'} heading="HOME" />
            </div>
            <div className="sidebar_menu_item">
                <SideBarButton route={'/coding'} heading="CODING" />
            </div>
            <div className="sidebar_menu_item">
                <SideBarButton route={'/art'} heading="ART" />
            </div>
            <div className="sidebar_menu_item">
                <SideBarButton route={'/blog'} heading="BLOG" />
            </div>
        </div>
    );
}

function SideBar() {
    return (
        <>
            <nav className="sidebar">
                <div className="sidebar_container">
                    <div className="sidebar_container_item">
                        <SideBarLogo onClick={changeTheme} heading="AEN" />
                    </div>
                    <div className="sidebar_container_item">
                        <SideBarMenu />
                    </div>
                </div>
            </nav>
        </>
    );
}

export default SideBar;
