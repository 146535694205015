import { useLocation, useNavigate } from 'react-router-dom';

import { FaInstagram, FaLinkedin, FaGithubSquare } from 'react-icons/fa';

import {
    FitPageSection,
    SectionItem,
    SectionRow,
} from '../page_components/PageComponents';
import { commonContent } from '../../../assets/content/Contents';

import './Footer.css';

function Footer() {
    const navigate = useNavigate();
    const location = useLocation();

    function SocialMediaButton(props) {
        return (
            <button
                onClick={() => {
                    window.open(props.url, '_blank');
                }}
                className="social_media_button"
            >
                {props.icon}
            </button>
        );
    }

    function FooterButton(props) {
        return (
            <button onClick={props.onClick} className="empty footer_button">
                {props.heading}
            </button>
        );
    }

    return (
        <FitPageSection bgColor="var(--background-color-shade-2)">
            <SectionRow>
                <SectionItem>
                    <SocialMediaButton
                        icon={<FaInstagram size="50" />}
                        url="https://www.instagram.com/aliemrenebiler/"
                    />
                </SectionItem>
                <SectionItem>
                    <SocialMediaButton
                        icon={<FaLinkedin size="50" />}
                        url="https://www.linkedin.com/in/aliemrenebiler/"
                    />
                </SectionItem>
                <SectionItem>
                    <SocialMediaButton
                        icon={<FaGithubSquare size="50" />}
                        url="https://github.com/aliemrenebiler"
                    />
                </SectionItem>
            </SectionRow>
            <SectionItem>
                <p className="small">{commonContent.footerText}</p>
            </SectionItem>
            <SectionItem>
                <FooterButton
                    heading="Release Notes"
                    onClick={() => {
                        const path = '/release-notes';
                        if (location.pathname !== path) navigate(path);
                    }}
                />
            </SectionItem>
        </FitPageSection>
    );
}

export default Footer;
