import { Page, Seperator } from '../../common/page_components/PageComponents';
import { WelcomeSection, AboutSection } from './HomeComponents';

function Home() {
    return (
        <Page>
            <WelcomeSection />
            <Seperator />
            <AboutSection />
        </Page>
    );
}

export default Home;
