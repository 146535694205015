import { Children, useRef, useState } from 'react';

import { commonContent } from '../../../assets/content/Contents';
import PlaceholderMessage from '../placeholder/PlaceholderMessage';
import ImageService from '../../../services/ImageService';

import './Gallery.css';
import { FitPageSection } from '../page_components/PageComponents';

function GalleryImage(props) {
    const [loading, setLoading] = useState(true);
    const [newWidth, setNewWidth] = useState(0);
    const imageRef = useRef();

    function calculateWidth(width, height, newHeight) {
        return (width / height) * newHeight;
    }

    return (
        <div
            className={
                loading
                    ? 'skeleton gallery_image_container'
                    : 'gallery_image_container'
            }
            style={
                loading
                    ? { width: 'calc(var(--gallery-image-height) * 1px)' }
                    : { width: `calc(${newWidth} * 1px)` }
            }
        >
            <img
                className="gallery_image"
                style={
                    loading
                        ? { opacity: 0, visibility: 'hidden' }
                        : { visibility: 'visible' }
                }
                ref={imageRef}
                src={`${props.src}?timestamp=${ImageService.setTimestamp()}`}
                alt={props.alt}
                loading="lazy"
                onLoad={() => {
                    setNewWidth(
                        calculateWidth(
                            imageRef.current.naturalWidth,
                            imageRef.current.naturalHeight,
                            imageRef.current.height
                        )
                    );
                    setLoading(false);
                }}
            />
        </div>
    );
}

function Gallery(props) {
    const arrayChildren = Children.toArray(props.children);

    function renderGalleryContent() {
        if (props.loading) {
            return (
                <div className="expand_and_center">
                    <PlaceholderMessage message={commonContent.loading} />
                </div>
            );
        } else if (arrayChildren.length > 0) {
            return (
                <FitPageSection>
                    <div className="gallery">
                        {arrayChildren.map((child, key) => {
                            return (
                                <div
                                    key={child.props.src}
                                    onClick={() => {
                                        props.onClickImage(key);
                                    }}
                                    className="gallery_item"
                                >
                                    {child}
                                </div>
                            );
                        })}
                    </div>
                </FitPageSection>
            );
        } else {
            return (
                <div className="expand_and_center">
                    <PlaceholderMessage message={commonContent.noImages} />
                </div>
            );
        }
    }

    return renderGalleryContent();
}

export { Gallery, GalleryImage };
