import { useEffect, useState, Children } from 'react';
import {
    FaRegHandPointLeft,
    FaRegHandPointRight,
    FaTimes,
} from 'react-icons/fa';

import {
    setScrollLock,
    useWindowSize,
    useWindowSizeLimits,
} from '../../../providers/WindowProvider';
import { commonContent } from '../../../assets/content/Contents';
import ImageService from '../../../services/ImageService';

import './Carousel.css';

function CarouselImage(props) {
    const [loading, setLoading] = useState(true);
    return (
        <div className="carousel_image_container">
            <img
                className="carousel_image"
                style={loading ? { opacity: 0 } : { opacity: 1 }}
                src={`${props.src}?timestamp=${ImageService.setTimestamp()}`}
                alt={props.alt}
                loading="lazy"
                onLoad={() => {
                    setLoading(false);
                }}
            />
        </div>
    );
}

function CarouselButton(props) {
    return (
        <button
            id={props.id}
            className="hollow carousel_button"
            onClick={props.onClick}
        >
            {props.children}
        </button>
    );
}

function CarouselMessage(props) {
    return (
        <div className="carousel_message">
            <h3>{props.message}</h3>
        </div>
    );
}

function Carousel(props) {
    const windowSize = useWindowSize();
    const windowSizeLimits = useWindowSizeLimits();
    const [imageIndex, setImageIndex] = useState(props.currentImageIndex);
    const [className, setClassName] = useState('hidden carousel');
    const arrayChildren = Children.toArray(props.children);

    useEffect(() => {
        if (props.visible) {
            setScrollLock(true);
            setImageIndex(props.currentImageIndex);
            setClassName('carousel');
        } else {
            setScrollLock(false);
            setClassName('hidden carousel');
        }
    }, [props.visible, props.currentImageIndex]);

    const nextImage = () => {
        if (imageIndex + 1 < arrayChildren.length) {
            setImageIndex(imageIndex + 1);
        } else {
            setImageIndex(0);
        }
    };

    const prevImage = () => {
        if (0 < imageIndex) {
            setImageIndex(imageIndex - 1);
        } else {
            setImageIndex(arrayChildren.length - 1);
        }
    };

    return (
        <div className={className}>
            <div className="carousel_row align_right">
                <CarouselButton
                    id="carousel_close_button"
                    onClick={() => {
                        props.onClickClose(false);
                    }}
                >
                    CLOSE
                    <div id="carousel_button_close_icon">
                        <FaTimes size="20" />
                    </div>
                </CarouselButton>
            </div>
            <div className="carousel_content">
                {arrayChildren.length > 0 ? (
                    arrayChildren[imageIndex]
                ) : (
                    <CarouselMessage message={commonContent.noImages} />
                )}
            </div>
            <div className="carousel_row">
                <CarouselButton
                    onClick={() => {
                        prevImage();
                    }}
                >
                    <div id="carousel_button_prev_icon">
                        <FaRegHandPointLeft size="25" />
                    </div>
                    {windowSize.width > windowSizeLimits.xsmall && 'PREV'}
                </CarouselButton>
                <div className="carousel_image_counter">
                    {imageIndex + 1 > 1000 ? '1000+' : imageIndex + 1}/
                    {arrayChildren.length > 1000
                        ? '1000+'
                        : arrayChildren.length}
                </div>
                <CarouselButton
                    onClick={() => {
                        nextImage();
                    }}
                >
                    {windowSize.width > windowSizeLimits.xsmall && 'NEXT'}
                    <div id="carousel_button_next_icon">
                        <FaRegHandPointRight size="25" />
                    </div>
                </CarouselButton>
            </div>
        </div>
    );
}

export { Carousel, CarouselImage };
