import { useEffect, useState } from 'react';

import { codingContent } from '../../../assets/content/Contents';
import {
    useWindowSize,
    useWindowSizeLimits,
} from '../../../providers/WindowProvider';
import {
    FitPageSection,
    Spacing,
    SectionItem,
    SectionRow,
} from '../../common/page_components/PageComponents';
import PlaceholderMessage from '../../common/placeholder/PlaceholderMessage';

import './Coding.css';

function ExplanationSection() {
    return (
        <FitPageSection bgColor="var(--background-color-shade-2)">
            <SectionItem>
                <p>
                    <b>{codingContent.explanationTextHighlight}</b>{' '}
                    {codingContent.explanationText}
                </p>
            </SectionItem>
        </FitPageSection>
    );
}

function ProjectsSection() {
    const windowSize = useWindowSize();
    const windowSizeLimits = useWindowSizeLimits();
    const isScreenWidthSmall = windowSize.width < windowSizeLimits.small;
    const [columnCount, setColumnCount] = useState(isScreenWidthSmall ? 1 : 2);

    useEffect(() => {
        setColumnCount(isScreenWidthSmall ? 1 : 2);
    }, [isScreenWidthSmall]);

    function ProjectButton(props) {
        return (
            <button
                onClick={() => {
                    props.url && window.open(props.url, '_blank');
                }}
                className="outlined project_button"
            >
                <div className="project_button_container">
                    <h3 className="project_button_title">{props.title}</h3>
                    <p className="small project_button_text">{props.text}</p>
                </div>
            </button>
        );
    }

    function renderProjectsContent() {
        if (!codingContent.projects || codingContent.projects.length < 1) {
            return (
                <div className="expand_and_center">
                    <PlaceholderMessage message={codingContent.noProjects} />
                </div>
            );
        } else {
            return (
                <FitPageSection>
                    <SectionRow columnCount={columnCount} expand>
                        {codingContent.projects.map((project, key) => {
                            return (
                                <SectionItem expand key={key}>
                                    <ProjectButton
                                        title={project.title}
                                        text={project.text}
                                        url={project.url}
                                    />
                                </SectionItem>
                            );
                        })}
                    </SectionRow>
                </FitPageSection>
            );
        }
    }

    return renderProjectsContent();
}

function GitHubSection() {
    function GitHubButton() {
        return (
            <button
                onClick={() => {
                    window.open('https://github.com/aliemrenebiler/', '_blank');
                }}
                className="outlined"
                id="github_button"
            >
                @aliemrenebiler on GitHub
            </button>
        );
    }

    return (
        <FitPageSection>
            <SectionItem>
                <h2 id="github_title">{codingContent.gitHubTitle}</h2>
            </SectionItem>
            <SectionItem>
                <p>{codingContent.gitHubText}</p>
            </SectionItem>
            <Spacing height="10px" />
            <SectionItem>
                <GitHubButton />
            </SectionItem>
        </FitPageSection>
    );
}

export { ExplanationSection, ProjectsSection, GitHubSection };
