import { FitPageSection, SectionItem } from '../page_components/PageComponents';

function PlaceholderMessage(props) {
    return (
        <FitPageSection>
            <SectionItem>
                <h3>{props.message}</h3>
            </SectionItem>
        </FitPageSection>
    );
}

export default PlaceholderMessage;
