import { useEffect, useState, Children } from 'react';
import { useBarType } from '../../../providers/WindowProvider';

import './PageComponents.css';

const calculatePageHeight = (barType) => {
    if (barType === 'sidebar') {
        return '100svh';
    } else if (barType === 'navbar') {
        return `calc(100svh - var(--navbar-height) * 1px)`;
    }
};

function Page(props) {
    const barType = useBarType();
    const calculatedPageHeight = calculatePageHeight(barType);
    const [pageHeight, setPageHeight] = useState(calculatedPageHeight);

    useEffect(() => {
        setPageHeight(calculatedPageHeight);
    }, [calculatedPageHeight]);

    return (
        <div className="page" style={{ minHeight: pageHeight }}>
            {props.children}
        </div>
    );
}

function FullPageSection(props) {
    const barType = useBarType();
    const calculatedPageHeight = calculatePageHeight(barType);
    const [pageHeight, setPageHeight] = useState(calculatedPageHeight);

    useEffect(() => {
        setPageHeight(calculatedPageHeight);
    }, [calculatedPageHeight]);

    return (
        <div
            id={props.id && props.id}
            className={
                props.alignTop ? 'align_top page_section' : 'page_section'
            }
            style={{
                minHeight: pageHeight,
                background: props.bgColor && props.bgColor,
            }}
        >
            {props.noContainer ? (
                props.children
            ) : (
                <div className="page_section_container">{props.children}</div>
            )}
        </div>
    );
}

function FitPageSection(props) {
    return (
        <div
            className="page_section"
            style={{ background: props.bgColor && props.bgColor }}
        >
            <div id={props.id && props.id} className="page_section_container">
                {props.children}
            </div>
        </div>
    );
}

function SectionRow(props) {
    const arrayChildren = Children.toArray(props.children);

    const renderColumns = (columnCount, arrayChildren) => {
        let columnContents = Array.apply(null, Array(columnCount)).map(() => {
            return [];
        });
        for (let i in arrayChildren) {
            columnContents[i % columnCount].push(arrayChildren[i]);
        }

        let columns = Array.apply(null, columnContents).map(
            (columnContent, key) => {
                return (
                    <div
                        key={key}
                        className={setClassName('', props.align, props.expand)}
                    >
                        {columnContent}
                    </div>
                );
            }
        );

        return columns;
    };

    return (
        <div
            className={setClassName(
                'page_section_container_row',
                props.align,
                props.expand
            )}
        >
            {props.columnCount
                ? renderColumns(
                      props.columnCount,
                      arrayChildren,
                      props.align,
                      props.expand
                  )
                : props.children}
        </div>
    );
}

function SectionItem(props) {
    return (
        <div
            className={setClassName(
                'page_section_container_item',
                props.align,
                props.expand
            )}
        >
            {props.children}
        </div>
    );
}

function Spacing(props) {
    return (
        <div
            className="spacing"
            style={{ width: props.width, height: props.height }}
        ></div>
    );
}

function Seperator(props) {
    return (
        <div
            className="seperator"
            style={{ backgroundColor: props.color }}
        ></div>
    );
}

const setClassName = (prefix, align, expand) => {
    let className = prefix;

    if (expand) {
        className = `${className} full_width`;
    }

    if (align === 'left') {
        className = `${className} align_left`;
    } else if (align === 'right') {
        className = `${className} align_right`;
    }

    return className;
};

export {
    Page,
    FullPageSection,
    FitPageSection,
    SectionRow,
    SectionItem,
    Spacing,
    Seperator,
};
