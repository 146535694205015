const themeCount = 4;
let themeIndex = 0;

const setShadeColor = () => {
    const shortHexRegex = /^#?([a-f\d]{1})([a-f\d]{1})([a-f\d]{1})$/i;
    const longHexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
    const shadeColor = getComputedStyle(
        document.documentElement
    ).getPropertyValue('--background-color');
    let rgbColors = [];
    if (shadeColor.length <= 4) {
        rgbColors = shortHexRegex.exec(shadeColor);
        rgbColors[1] += rgbColors[1];
        rgbColors[2] += rgbColors[2];
        rgbColors[3] += rgbColors[3];
    } else {
        rgbColors = longHexRegex.exec(shadeColor);
    }
    const r = parseInt(rgbColors[1], 16);
    const g = parseInt(rgbColors[2], 16);
    const b = parseInt(rgbColors[3], 16);
    const brightness = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    document.documentElement.style.setProperty(
        '--shade-color',
        brightness < 40 ? 'white' : 'black'
    );
};

const setTheme = (themeName) => {
    document.documentElement.className = themeName;
    setShadeColor();
};

const changeTheme = () => {
    themeIndex++;
    if (themeIndex >= themeCount) {
        themeIndex = 0;
    }
    setTheme(`theme-${themeIndex}`);
};

export { setTheme, changeTheme };
