import { Page } from '../../common/page_components/PageComponents';
import { ReleaseNotesSection, TopSection } from './ReleaseNotesComponents';

function ReleaseNotes() {
    return (
        <Page>
            <TopSection />
            <ReleaseNotesSection />
        </Page>
    );
}

export default ReleaseNotes;
