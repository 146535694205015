import { commonContent } from '../../../assets/content/Contents';
import {
    FullPageSection,
    Page,
} from '../../common/page_components/PageComponents';
import PlaceholderMessage from '../../common/placeholder/PlaceholderMessage';

function NotFound() {
    return (
        <Page>
            <FullPageSection noContainer>
                <div className="expand_and_center">
                    <PlaceholderMessage message={commonContent.notFound} />
                </div>
            </FullPageSection>
        </Page>
    );
}

export default NotFound;
