import {
    Page,
    FullPageSection,
    Seperator,
} from '../../common/page_components/PageComponents';
import {
    ExplanationSection,
    ProjectsSection,
    GitHubSection,
} from './CodingComponents';

function Coding() {
    return (
        <Page>
            <FullPageSection noContainer alignTop>
                <ExplanationSection />
                <ProjectsSection />
            </FullPageSection>
            <Seperator />
            <GitHubSection />
        </Page>
    );
}

export default Coding;
